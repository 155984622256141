@use '@angular/material' as mat;
@import "~ag-grid/dist/styles/ag-grid.css";
@import "~ag-grid/dist/styles/ag-theme-balham.css";

@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
@import url("@fortawesome/fontawesome-free/css/all.min.css");

@import url("~trumbowyg/dist/ui/trumbowyg.min.css");
@import url("~trumbowyg/dist/plugins/table/ui/trumbowyg.table.min.css");

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

$blue: #133559;
$md-blue: (
  50: #e0eaf3,
  100: #b3cbe1,
  200: #80a9ce,
  300: #4d87ba,
  400: #266dab,
  500: #133559,
  600: #004c94,
  700: #00428a,
  800: #003980,
  900: #00296e,
  A100: #9db9ff,
  A200: #6a95ff,
  A400: #3770ff,
  A700: #1e5eff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);
$md-green: (
  50: #e3f1ea,
  100: #badbcc,
  200: #8cc3aa,
  300: #5eab87,
  400: #3c996e,
  500: #198754,
  600: #167f4d,
  700: #127443,
  800: #0e6a3a,
  900: #085729,
  A100: #8bffb5,
  A200: #58ff94,
  A400: #25ff74,
  A700: #0bff63,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

@import "~node_modules/bootstrap/scss/bootstrap";
// @import '@angular/material/prebuilt-themes/indigo-pink.css';
@import "@angular/material/theming";
@include mat.core();

$my-app-primary: mat.define-palette($md-blue);
$my-app-accent: mat.define-palette($md-green);
$my-app-warn: mat.define-palette(mat.$deep-orange-palette);

$my-app-theme: mat.define-light-theme($my-app-primary, $my-app-accent, $my-app-warn);
@include mat.all-component-themes($my-app-theme);

@import "~ngx-toastr/toastr";

app-sidebar {
  .mat-expansion-panel-body {
    padding: 0 24px 0px !important;
    overflow: hidden;
  }
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
  margin-bottom: 1em;
}

mat-form-field {
  width: 100%;
}
.cdk-overlay-pane {
  max-width: 95vw !important;
  // max-height: 90vh;
  // overflow: hidden;
  // overflow-y: auto;
}
mat-dialog-container {
  padding: 15px !important;
}

.title-form {
  h3 {
    margin-bottom: 0 !important;
  }
  hr {
    margin: 0 0 10px 0;
  }
}
button:focus {
  outline: none !important;
}
.example-headers-align .mat-expansion-panel-header-description {
  justify-content: space-between !important;
  align-items: center !important;
}
.title-module {
  margin-bottom: 0px !important;
  display: inline-flex;
  div {
    button {
      height: 25px;
      font-size: 14px;
      line-height: normal;
      mat-icon {
        height: 15px;
        width: 15px;
        font-size: 13px;
        font-weight: bold;
      }
    }
  }
}
.mat-form-field-required-marker {
  color: red !important;
  font-weight: 400 !important;
}
.mat-select-panel {
  max-width: 90vh !important;
}

.btn-file {
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

.mat-expansion-panel-content {
  overflow-y: auto !important;
}

.mat-dialog-container {
  max-height: 95vh !important;
}

signature-pad {
  background: transparent !important;
  display: block !important;
}

#fr-logo {
  display: none !important;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #838383 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #838383;
  border-radius: 5px;
  border: 3px solid #ffffff;
}
.table > :not(:first-child) {
  border-top: 0px;
}
.ag-theme-balham .ag-paging-panel {
  color: black;
}

.ag-theme-balham button[ref="btPrevious"][disabled],
.ag-theme-balham button[ref="btNext"][disabled] {
  border: none;
  border-radius: initial;
}
.ag-theme-balham button[ref="btPrevious"],
.ag-theme-balham button[ref="btNext"] {
  border: 1px solid gray;
  border-radius: 3px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}

.cellCurrencyStyle {
  text-align: end;
}
.color-blue-rh{
  color: #133559;
}
.fs-small{
  font-size: small;
}
